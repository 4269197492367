import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  FileUploaderDropContainer,
  FileUploaderItem,
  Button,
  Dropdown,
} from 'carbon-components-react';

import './_upload-patterns.scss';

const UploadPatterns = ({ onUpload, onChangeType, types, type, isUploading }) => {
  const [files, setFiles] = React.useState([]);
  const [arrFiles, setArrFiles] = React.useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // const arrPromises = [];
    // files.forEach((value) => {
    //   arrPromises.push(
    //     new Promise((resolve, reject) => {
    //       const reader = new FileReader();
    //       reader.readAsDataURL(value.file);
    //       reader.onload = async (e) => {
    //         try {
    //           const data = e.target.result;
    //           resolve({ [value.name]: data });
    //         } catch (err) {
    //           reject(err);
    //         }
    //       };
    //     })
    //   );
    // });

    // Promise.all(arrPromises).then((values) => {
    //   onUpload(values);
    // });

    onUpload(arrFiles);
  };

  const handleAddFiles = (e, { addedFiles }) => {
    e.stopPropagation();
    setArrFiles([...addedFiles]);
    const newFiles = addedFiles.map((file) => {
      return {
        uuid: uuidv4(),
        name: file.name,
        status: 'edit',
        iconDescription: 'Uploading',
        file,
      };
    });
    setFiles([...files, ...newFiles]);
  };

  const handleFileUploaderItemClick = React.useCallback(
    (e, { uuid }) => {
      e.preventDefault();
      setFiles(files.filter((item) => item.uuid !== uuid));
    },
    [files]
  );

  const renderFiles = React.useCallback(() => {
    return (
      <>
        {files.map(({ uuid, name, iconDescription, invalid, ...rest }) => (
          <div style={{ marginTop: 8 }}>
            <FileUploaderItem
              key={uuid}
              uuid={uuid}
              name={name}
              size="default"
              status="complete"
              iconDescription={iconDescription}
              invalid={invalid}
              onDelete={handleFileUploaderItemClick}
              {...rest}
            />
          </div>
        ))}
      </>
    );
  }, [files, handleFileUploaderItemClick]);

  return (
    <div>
      <FileUploaderDropContainer
        title="Upload Pattern(s)"
        description="Only .dxf files."
        accept={['.dxf']}
        labelText="Drag and drop files here or upload"
        tabIndex={0}
        onAddFiles={handleAddFiles}
        multiple
        disabled={isUploading}
      />
      <div className="bx--file__container">{renderFiles()}</div>
      <div style={{ marginTop: 32 }}>
        <Dropdown
          id="type"
          titleText="Product type"
          label="Type"
          items={types}
          itemToString={(item) => (item ? item.label : '')}
          onChange={onChangeType}
          selectedItem={type}
        />
      </div>
      <Button
        className="onRight button-submit"
        type="submit"
        onClick={handleSubmit}
        disabled={isUploading || files.length === 0}
      >
        {isUploading ? 'Generating...' : 'Next'}
      </Button>
    </div>
  );
};
export { UploadPatterns };
