export const APP_URL = 'http://api-lectra.app.sixatomic.com';

export const SYN_APP_URL = 'https://api-dev-synthesis.app.sixatomic.com';

export const VALID_FILES = [
  '1_9SH04.json',
  '9SH01A.json',
  '9SH02A.json',
  'chemise_9.json',
  'PVH_AC_L3272HP_-_33K2479_COL_SMOKE_PO_TBA_Test.json',
  'BSK_d442c204_Test.json',
  'H_M_27963-1-1_Test.json',
  'LH197182_Test.json',
  'RalphLaurenShirtDXF.json',
  'Zozo_MSH001B.json',
  'Zozo_MSH003B.json',
  'Zozo_MSH006B.json',
  'Zozo_st_msh01.json',
];
