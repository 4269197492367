import React from "react";
import PropTypes from "prop-types";
import { SvgProxy } from "react-svgmt";

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const SvgSelectElement = (props) => {
  const { selector, ...other } = props;
  return <SvgProxy selector={selector} {...other} />;
};

SvgSelectElement.defaultProps = {
  selector: PropTypes.string.isRequired,
};

export default SvgSelectElement;
