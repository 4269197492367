import React from 'react';
import { AutoSizer } from 'react-virtualized';
import { Button, Dropdown, Checkbox } from 'carbon-components-react';
import { UncontrolledReactSVGPanZoom } from 'react-svg-pan-zoom';
import { SvgPanZoomLoader, SvgSelectElement } from '../../../component/common';

import 'react-virtualized/styles.css';
import './show-pattern.scss';
import { magic } from '../../../actions';
import { VALID_FILES } from '../../../utils/constants';
import { isEqual } from 'lodash';

const PLEAT_TYPES = [{ name: 'Pin Tuck' }, { name: 'Pleat' }, { name: 'Not a Pleat' }];

const ShowPattern = ({ selectedPath, pieceData, labelListData, dataFile, handleGetOptions }) => {
  const [svgXML, setSvgXML] = React.useState('');
  const [activeIndex, setActiveIndex] = React.useState('');
  const [activeBlockId, setActiveBlockId] = React.useState('');
  // const [type, setType] = React.useState('');
  const [data, setData] = React.useState({});
  const [selectedPleat, setSelectedPleat] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSwitchDirection, setIsSwitchDirection] = React.useState(false);

  const proxySelectors = ['.entity.pleat'];

  React.useEffect(() => {
    if (!Object.keys(pieceData).length) return;
    setData(pieceData[selectedPath]?.patternJson);
    // setType(pieceData.type);
  }, [selectedPath, pieceData]);

  const _convertPoints = (dataPoints) => {
    let points = '';
    if (!dataPoints || !dataPoints.length) return points;
    dataPoints.forEach((item) => {
      points += `${item.x},${item.y} `;
    });
    return points;
  };

  const renderEntity = React.useCallback((entity) => {
    switch (entity.type.toLowerCase()) {
      case 'line':
      case 'polyline':
        return `<g color="#757575">
          <polyline
            xmlns="http://www.w3.org/2000/svg"
            fill="transparent"
            stroke="currentColor"
            stroke-width="${entity.shape ? 0 : 2}"
            points="${_convertPoints(entity.vertices)}"
          />
        </g>`;
      default:
        return '';
    }
  }, []);

  const renderDirection = React.useCallback((startPoint, directionArrow = 'end', className) => {
    const { x, y } = startPoint;
    switch (directionArrow) {
      case 'start':
        return `<g class="pink-arrow ${className}">
            <defs>
              <marker id="startarrow" markerWidth="5" markerHeight="7" 
              refX="5" refY="3.5" orient="auto">
                <polygon points="5 0, 5 7, 0 3.5" fill="#fe0190" />
              </marker>
            </defs>
            <line x1="${x - 24}" y1="${y}" x2="${x}" y2="${y}" stroke="#fe0190" 
            stroke-width="1" marker-start="url(#startarrow)" />
          </g>`;
      case 'end':
        return `<g class="pink-arrow ${className}">
            <defs>
              <marker id="endarrow" markerWidth="5" markerHeight="7" 
              refX="0" refY="3.5" orient="auto">
                <polygon points="0 0, 5 3.5, 0 7" fill="#fe0190" />
              </marker>
            </defs>
            <line x1="${x}" y1="${y}" x2="${x + 24}" y2="${y}" stroke="#fe0190" 
            stroke-width="1" marker-end="url(#endarrow)" />
          </g>`;
      default:
        return '';
    }
  }, []);

  const renderPleat = React.useCallback(
    (pleat, index, blockId) => {
      return `<g
        class="entity pleat ${
          index === Number(activeIndex) && activeBlockId === blockId ? 'active' : ''
        }"
        data-block="${blockId}"
        data-index="${index}"
        data-pleat="${pleat.pleatType}"
        color="rgba(15, 98, 254, 0.25)"
      >
        <polyline
          key="${blockId}-higherPleat-${index}"
          xmlns="http://www.w3.org/2000/svg"
          fill="transparent"
          stroke="currentColor"
          stroke-width="${pleat.shape ? 0 : 3}"
          points="${_convertPoints(pleat.higherPleat.vertices)}"
        />
        <polyline
          key="${blockId}-lowerPleat-${index}"
          xmlns="http://www.w3.org/2000/svg"
          fill="transparent"
          stroke="currentColor"
          stroke-width="${pleat.shape ? 0 : 3}"
          points="${_convertPoints(pleat.lowerPleat.vertices)}"
        />
        ${renderDirection(
          pleat.higherPleat.vertices[0].y < pleat.higherPleat.vertices[1].y
            ? pleat.higherPleat.vertices[0]
            : pleat.higherPleat.vertices[1],
          pleat.direction,
          isEqual(selectedPleat, pleat) && activeBlockId === blockId ? 'active' : ''
        )}
      </g>`;
    },
    [activeIndex, activeBlockId, renderDirection, selectedPleat]
  );

  const _calcCenterX = (x, text) => {
    if (!text) return x;
    return parseFloat(x) - (text.toString().length * 8) / 2;
  };

  const _calcCenterY = (y) => {
    return parseFloat(y) - 2;
  };

  React.useEffect(() => {
    if (!data || !Object.keys(data).length) return;

    let svg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="-120 -100 5000 1600">';
    Object.keys(data.blocks).forEach((blockId) => {
      const item = data.blocks[blockId];
      const labelPos =
        item.pieceProperties && item.pieceProperties.labelPosition
          ? item.pieceProperties.labelPosition
          : { x: 0, y: 0 };
      const positionObj = data.entities.find(
        (item) => item.type === 'INSERT' && item.name === blockId
      );
      const pleats = [];
      (item.pleatsInfo?.pleats || []).forEach((obj) => {
        pleats.push({
          ...obj,
          pleatType: 'Pleat',
          direction: obj.higherPleat.vertices[0].x > obj.lowerPleat.vertices[0].x ? 'start' : 'end',
        });
      });
      svg += `<g
        xmlns="http://www.w3.org/2000/svg"
        id="${blockId}"
        style="transform: translate(${positionObj?.position?.x || 0}px, ${
        positionObj?.position?.y || 0
      }px);"
        color="#888"
      >
        ${item.entities.map((entity) => renderEntity(entity))}
        ${pleats.map((pleat, index) => renderPleat(pleat, index, blockId))}
        <text
          xmlns="http://www.w3.org/2000/svg"
          x="${_calcCenterX(labelPos.x, item.name)}"
          y="${_calcCenterY(labelPos.y)}"
          fill="#161616"
          font-size="16">
          ${item.name || ''}
        </text>
      </g>`;
    });
    svg += '</svg>';
    setSvgXML(svg);
  }, [data, selectedPleat, renderEntity, renderPleat]);

  const onElementSelected = (els) => {
    if (!els) return;
    const listEl = els.length ? els : [els];
    listEl.forEach((el) => {
      el.addEventListener('click', () => {
        const index = el.getAttribute('data-index');
        const blockId = el.getAttribute('data-block');
        const pleatType = el.getAttribute('data-pleat');
        if (index && blockId) {
          const newSelectedPleat = data.blocks[blockId].pleatsInfo.pleats[index];
          let pleat = { pleatType };
          if (pleatType === 'Pleat') {
            pleat = {
              ...pleat,
              ...newSelectedPleat,
              direction:
                newSelectedPleat.higherPleat.vertices[0].x >
                newSelectedPleat.lowerPleat.vertices[0].x
                  ? 'start'
                  : 'end',
            };
          } else {
            pleat = {
              ...pleat,
              ...data.blocks[blockId].pleatsInfo.pinTucks[index],
            };
          }
          setActiveIndex(index);
          setActiveBlockId(blockId);
          setSelectedPleat(pleat);
          setIsSwitchDirection(false);
        }
      });
    });
  };

  const handleSetDataAndUpdatePieceData = React.useCallback(
    (newData) => {
      pieceData[selectedPath] = newData;
      setData(newData);
    },
    [pieceData, selectedPath]
  );

  const handleMagic = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const selectedDxf = selectedPath.replace('.json', '.dxf');
      const selectedDataFile = { data: dataFile.data.filter((file) => file[selectedDxf]) };
      const res = await magic().send(selectedDataFile);
      setIsLoading(false);
      handleSetDataAndUpdatePieceData(res.body[selectedPath]);
    } catch (err) {
      console.error('Error when calling api magic: ', err);
    }
  }, [dataFile, selectedPath, handleSetDataAndUpdatePieceData]);

  const magicDetect = () => {
    handleMagic();
  };

  const handleChangePleatType = ({ selectedItem }) => {
    let newData = { ...data };
    newData.blocks[activeBlockId].entities[activeIndex].name = selectedItem.id;
    handleSetDataAndUpdatePieceData(newData);
    setSelectedPleat({
      ...selectedPleat,
      pleatType: selectedItem.name,
    });
  };

  const onChangeDirection = (checked) => {
    setIsSwitchDirection(checked);
    const newData = { ...data };
    const key = selectedPleat.pleatType === 'Pleat' ? 'pleats' : 'pinTucks';
    const temp = newData.blocks[activeBlockId].pleatsInfo[key][activeIndex].higherPleat;
    newData.blocks[activeBlockId].pleatsInfo[key][activeIndex].higherPleat =
      newData.blocks[activeBlockId].pleatsInfo[key][activeIndex].lowerPleat;
    newData.blocks[activeBlockId].pleatsInfo[key][activeIndex].lowerPleat = temp;
    setData(newData);
    const newSelectedPleat = { ...selectedPleat };
    const t = { ...newSelectedPleat.higherPleat };
    newSelectedPleat.higherPleat = { ...newSelectedPleat.lowerPleat };
    newSelectedPleat.lowerPleat = t;
    newSelectedPleat.direction = selectedPleat.direction === 'start' ? 'end' : 'start';
    setSelectedPleat(newSelectedPleat);
  };

  return (
    <AutoSizer className="AutoSizer">
      {({ width, height }) =>
        width !== 0 &&
        height !== 0 && (
          <div id="SVG-container" className={`${isLoading ? '--disabled' : ''}`}>
            <div className="svg__toolbar">
              <div className="svg__toolbar__dropdown">
                {activeBlockId && activeIndex && (
                  <div className="svg__toolbar__dropdown__wrapper">
                    <Dropdown
                      id="pleat-type"
                      titleText="Pleat Type:"
                      label="Choose a name"
                      type="inline"
                      items={PLEAT_TYPES}
                      itemToString={(item) => (item ? item.name : '')}
                      onChange={handleChangePleatType}
                      selectedItem={{ name: selectedPleat?.pleatType || '' }}
                    />
                    <Checkbox
                      id="folding-direction"
                      labelText="Switch folding direction"
                      checked={isSwitchDirection}
                      onChange={(checked) => onChangeDirection(checked)}
                    />
                  </div>
                )}
              </div>
              {VALID_FILES.includes(selectedPath) && (
                <Button className="btn-magic" onClick={magicDetect} disabled={isLoading}>
                  {isLoading ? 'Detecting...' : 'Update detected labels'}
                </Button>
              )}
            </div>
            <SvgPanZoomLoader
              svgXML={svgXML}
              proxy={
                <>
                  {proxySelectors.map((selector) => (
                    <SvgSelectElement
                      key={selector}
                      selector={selector}
                      onElementSelected={onElementSelected}
                    />
                  ))}
                </>
              }
              render={(content) => (
                <div>
                  <UncontrolledReactSVGPanZoom
                    width={width}
                    height={height - 144}
                    SVGBackground="#f4f4f4"
                    background="#f4f4f4"
                  >
                    <svg width={width} height={height - 144}>
                      {content}
                    </svg>
                  </UncontrolledReactSVGPanZoom>
                </div>
              )}
            />
          </div>
        )
      }
    </AutoSizer>
  );
};

export default ShowPattern;
