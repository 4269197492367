import React from 'react';
import {
  Header,
  HeaderGlobalBar,
  SkipToContent,
} from 'carbon-components-react/lib/components/UIShell';
import Logo from './logo.svg';

import './Header.scss';

const Headerbar = () => (
  <Header aria-label="Carbon Tutorial">
    <img className="header-logo" src={Logo} alt="six_logo" />
    <SkipToContent />
    <span className="name">ZOZO POC</span>
    <HeaderGlobalBar></HeaderGlobalBar>
  </Header>
);

export default Headerbar;
