import React from 'react';
import { Content } from 'carbon-components-react/lib/components/UIShell';
import { ToastContainer } from 'react-toastify';
import Headerbar from './component/Header/Headerbar';
import LandingPage from './content/LandingPage';

import 'react-toastify/dist/ReactToastify.css';

import './App.scss';

const App = () => {
  return (
    <>
      <Headerbar />
      <ToastContainer autoClose={3000} />
      <Content className="full-Height" style={{ backgroundColor: '#f4f4f4' }}>
        <LandingPage />
      </Content>
    </>
  );
};

export default App;
