import React from 'react';
import { Loading } from 'carbon-components-react';

import ShowPattern from './ShowPattern';
import DropdownOption from '../Option/DropdownOption';

import './PleatDetection.scss';

const PleatDetection = ({
  inputPath,
  options,
  pieceData,
  labelListData,
  dataFile,
  handleGetOptions,
  type,
}) => {
  const [state, setState] = React.useState({
    selectedPath: '',
    optionsPath: [],
    optionJson: [],
  });
  const titleDropdown = 'Select pattern to preview';

  React.useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      selectedPath: inputPath[0],
    }));
  }, [inputPath]);

  const selectOption = (e) => {
    const path = e[titleDropdown];
    console.log('new', path);
    setState((prevState) => ({ ...prevState, selectedPath: path }));
  };

  const svgOption =
    inputPath && inputPath.length > 0 ? (
      <DropdownOption
        data={titleDropdown}
        options={inputPath || []}
        onSelectOption={selectOption}
        selectedItem={state.selectedPath}
      />
    ) : (
      <p>Loading</p>
    );

  console.log({ inputPath, pieceData, 11111111111111: state });

  return (
    <div className="PleatDetection">
      <div className="bx--col bx--no-gutter inline">
        <div className="PleatDetection-wrap">
          <h3 className="tabs-style__title">Pleat Detection</h3>
          <p className="tabs-style__des">Review detected pleats and folding directions.</p>
        </div>

        <div>
          {inputPath.length > 0 ? (
            <div className="fullsize modular-tab">
              {svgOption}
              <ShowPattern
                selectedPath={state.selectedPath}
                pieceData={{
                  ...pieceData.patternJsons,
                  type: type,
                }}
                labelListData={labelListData}
                dataFile={dataFile}
                handleGetOptions={handleGetOptions}
              />
            </div>
          ) : (
            <Loading
              className="centerAlign"
              description="Active loading indicator"
              withOverlay={false}
              small={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default PleatDetection;
