import React from 'react';
import { Tabs, Tab, Button } from 'carbon-components-react';
import { toast } from 'react-toastify';
import { UploadPatterns } from '../UploadPatterns/UploadPatterns';
// import Modular from '../Modular';
import {
  getLabelList,
  convertPattern,
  // getClassifyPattern,
  getSynOptions,
  // getSegmentEntities,
  // detectGradedStack,
  // filterGradedStack,
  detectPleats,
} from '../../actions';
// import ModularDesign from '../ModularDesign';
// import { getVertices, getPolygonCentroid } from '../../utils/getLabelPosition';
import PleatDetection from '../PleatDetection';

export const TAB_UPLOAD = 0;
export const TAB_SETUP = 1;
export const TAB_DESIGN = 2;
const PRODUCT_TYPES = [
  {
    label: 'Shirt',
    value: 'shirt',
  },
  {
    label: 'Jeans',
    value: 'jeans',
  },
];

const LandingPage = () => {
  const [state, setState] = React.useState({
    filePath: [],
    options: [],
    type: PRODUCT_TYPES[0],
  });
  const [isUploading, setIsUploading] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState(TAB_UPLOAD);

  // it will be modified on ShowSVG component
  const [pieceData, setPieceData] = React.useState({});
  const [labelListData, setLabelListData] = React.useState({});

  const [dataOptions, setDataOptions] = React.useState({});
  const [dataFile, setDataFile] = React.useState({});
  // const [fullData, setFullData] = React.useState({});

  const handleGetOptions = React.useCallback(
    async (data) => {
      try {
        const newData = data ? data : pieceData;
        if (!newData || !Object.keys(newData).length) return;
        const response = await getSynOptions().send(newData);
        setDataOptions(
          Object.values(response.body).length > 0 && response.body.options
            ? response.body.options
            : {}
        );
        if (response.body.warningMessage) {
          throw response.body.warningMessage;
        }
      } catch (errMsg) {
        console.error('Error when calling API get options: ', errMsg);
        return toast.dark(
          <div>
            {errMsg.split('\n').map((item, key) => (
              <span key={key}>
                {item}
                <br />
              </span>
            ))}
          </div>,
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          }
        );
      }
    },
    [pieceData]
  );

  React.useEffect(() => {
    handleGetOptions();
  }, [handleGetOptions]);

  // const formatData = (data) => {
  //   let blocks = {};
  //   for (const [key, value] of Object.entries(data.blocks)) {
  //     blocks = {
  //       ...blocks,
  //       [key]: {
  //         ...value,
  //         pieceProperties2: {
  //           ...value.pieceProperties,
  //           labelPosition: {
  //             x: getPolygonCentroid(getVertices([...value.entities]))[0],
  //             y: getPolygonCentroid(getVertices([...value.entities]))[1],
  //           },
  //         },
  //       },
  //     };
  //   }
  //   return {
  //     ...data,
  //     blocks,
  //   };
  // };

  const handleGetDetectPieceData = React.useCallback(
    async (data) => {
      try {
        setIsUploading(true);
        const formData = new FormData();
        data.forEach((file) => {
          formData.append('files', file, file.name);
        });
        const response = await convertPattern().send(formData);
        const detectedPleats = await detectPleats().send({
          ...response.body,
        });
        // const gradedStack = await detectGradedStack().send({
        //   ...response.body,
        // });
        // const filteredStack = await filterGradedStack().send({
        //   ...gradedStack.body,
        // });
        // const segmentEntities = await getSegmentEntities().send({
        //   patternJson: filteredStack.body.patternJson,
        //   patternJsonUUID: filteredStack.body.patternJsonUUID,
        //   gradedStack: filteredStack.body.gradedStack,
        // });
        // const classifyPattern = await getClassifyPattern().send({
        //   ...segmentEntities.body,
        //   productType: state.type.value,
        // });
        setIsUploading(false);
        const fileNames = data.map((file) => file.name);
        setPieceData({
          // patternJsons: {
          //   [fileName.slice(0, -1).join('.')]: formatData(classifyPattern.body.patternJson),
          // },
          patternJsons: {
            ...detectedPleats.body,
          },
        });
        // setFullData({
        //   patternJson: classifyPattern.body.patternJson,
        //   patternJsonUUID: classifyPattern.body.patternJsonUUID,
        //   gradedStack: classifyPattern.body.gradedStack,
        //   name: fileName.slice(0, -1).join('.'),
        // });
        setState((prevState) => ({
          ...prevState,
          filePath: [...prevState.filePath, ...fileNames],
        }));
        handleChangeTab(TAB_SETUP);
      } catch (err) {
        console.error('Error when uploading: ', err);
      }
    },
    // [state.type]
    []
  );

  const handleGetLabelListData = React.useCallback(async () => {
    try {
      if (!pieceData || !Object.keys(pieceData).length) return;
      const response = await getLabelList();
      setLabelListData(response.body);
    } catch (err) {
      console.error('Error when calling api get label list: ', err);
    }
  }, [pieceData]);

  React.useEffect(() => {
    handleGetLabelListData();
  }, [handleGetLabelListData]);

  const handleChangeTab = (tabIndex) => {
    setSelectedTab(tabIndex);
  };

  const handleUpload = async (bitFile) => {
    const dataFileUpload = { data: bitFile };
    setDataFile(dataFileUpload);
    handleGetDetectPieceData(bitFile);
  };

  return (
    <div className="bx--grid--full-width landing-page ">
      <div className="landing-page__banner ">
        <div className="bx--col-lg-16 ">
          <h1 className="landing-page__title">ZOZO POC</h1>
        </div>
      </div>

      <div className="landing-page__r2">
        <div className="bx--col bx--no-gutter">
          <Tabs
            className="tabs-style"
            selected={selectedTab}
            onSelectionChange={handleChangeTab}
            selectionMode="manual"
            type="container"
          >
            <Tab id="tab-upload" label="1. Upload Pattern">
              <div className="bx--grid bx--grid--no-gutter bx--grid--full-width full-height">
                <div className="bx--row landing-page__tab-content setcenter">
                  <div className="bx--col-lg-10 tabmarginbox">
                    <h3 className="tabs-style__title">Upload Pattern</h3>
                    <p className="tabs-style__des secondaryColor tabbottom label-size">
                      Upload one or more patterns. Only .dxf (AAMA) is supported.
                    </p>
                    <UploadPatterns
                      onChangeType={({ selectedItem }) =>
                        setState((prevState) => ({ ...prevState, type: selectedItem }))
                      }
                      types={PRODUCT_TYPES}
                      type={state.type}
                      onUpload={handleUpload}
                      isUploading={isUploading}
                    />
                    <div className="bx--file__container"></div>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab id="tab-setup" label="2. Pleat Detection">
              <div className="bx--grid bx--grid--no-gutter bx--grid--full-width full-height">
                <div className="bx--row landing-page__tab-content setcenter">
                  <div className="bx--col-lg-10 tabmarginbox">
                    <PleatDetection
                      inputPath={state.filePath}
                      options={dataOptions}
                      pieceData={pieceData}
                      labelListData={labelListData}
                      dataFile={dataFile}
                      handleGetOptions={handleGetOptions}
                      type={state.type.value}
                    />
                    <Button
                      className="onRight inline relative button-submit"
                      type="submit"
                      parse=""
                      onClick={() => handleChangeTab(TAB_DESIGN)}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </Tab>

            {/* <Tab id="tab-setup" label="3. Pattern Classification">
              <div className="bx--grid bx--grid--no-gutter bx--grid--full-width full-height">
                <div className="bx--row landing-page__tab-content setcenter">
                  <div className="bx--col-lg-10 tabmarginbox">
                    <Modular
                      inputPath={state.filePath}
                      options={dataOptions}
                      pieceData={pieceData}
                      labelListData={labelListData}
                      dataFile={dataFile}
                      handleGetOptions={handleGetOptions}
                      type={state.type.value}
                    />
                    <Button
                      className="onRight inline relative button-submit"
                      type="submit"
                      parse=""
                      onClick={() => handleChangeTab(TAB_DESIGN)}
                    >
                      Next
                    </Button>
                  </div>
                </div>
              </div>
            </Tab>

            <Tab id="tab-design" label="4. Combine Components">
              <div className="bx--grid bx--grid--no-gutter bx--grid--full-width">
                <div className="bx--row landing-page__tab-content setcenter">
                  <div className="bx--col-lg-10 tabmarginbox">
                    <h3 className="tabs-style__title modpadding">Combine components</h3>
                    <p className="tabs-style__des" style={{ paddingBottom: 8 }}>
                      Combine non-grading components from different patterns to create a new design.
                    </p>
                    <ModularDesign
                      pieceData={fullData}
                      dataOptions={dataOptions}
                      type={state.type.value}
                    />
                  </div>
                </div>
              </div>
            </Tab> */}
          </Tabs>
        </div>
      </div>
    </div>
  );
};
export default LandingPage;
