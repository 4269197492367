import superagent from 'superagent';
import { APP_URL, SYN_APP_URL } from './constants';

const request = (url = '/', method = 'get', headers = {}) =>
  superagent[method](`${APP_URL}${url}`).set(headers);

export const requestSyn = (url = '/', method = 'get', headers = {}) =>
  superagent[method](`${SYN_APP_URL}${url}`).set(headers);

export default request;
