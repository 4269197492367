import React from "react";
import PropTypes from "prop-types";
import { SvgLoader } from "react-svgmt";

/**
 * Loading the svg file using react-svgmt
 * @param props
 * @returns {*}
 * @constructor
 */
const SvgPanZoomLoader = ({ src, proxy, render, ...restProps }) => {
  return <>{render(<SvgLoader {...restProps}>{proxy}</SvgLoader>)}</>;
};

SvgPanZoomLoader.defaultProps = {
  proxy: "",
};

SvgPanZoomLoader.propTypes = {
  // src: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  proxy: PropTypes.node,
};

export default SvgPanZoomLoader;
