import request, { requestSyn } from './utils/request';

export const getDetectPiece = () => {
  return request('/detect-piece', 'post');
};

export const detectEntity = () => {
  return request('/detect-entity', 'post');
};

export const getOptions = () => {
  return request('/options', 'post');
};
export const draft = () => {
  return request('/draft', 'post');
};

export const getLabelList = () => {
  return request('/label-list');
};

export const magic = () => {
  return request('/detect-piece-string', 'post');
};

const headers = {
  'zozo-poc-api-key': '067456f1-e4a1-4e84-8d5a-a17fc38233ac',
};

export const convertPattern = () => {
  return requestSyn('/zozo/detection/convert-dxf-to-json', 'post', headers);
};

export const getClassifyPattern = () => {
  return requestSyn('/zozo/detection/classify-pattern', 'post', headers);
};

export const getSynOptions = () => {
  return requestSyn('/zozo/options', 'post', headers);
};

export const getSegmentEntities = () => {
  return requestSyn('/zozo/detection/segment-entities', 'post', headers);
};

export const detectGradedStack = () => {
  return requestSyn('/zozo/detection/detect-graded-stack', 'post', headers);
};

export const filterGradedStack = () => {
  return requestSyn('/zozo/detection/filter-graded-stack', 'post', headers);
};

export const combineComponents = () => {
  return requestSyn('/zozo/detection/combine-components', 'post', headers);
};

export const detectPleats = () => {
  return requestSyn('/zozo/detection/detect-pleats', 'post', headers);
};
