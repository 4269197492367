export function convertCamelCaseToUpperCase(text) {
  const textList = text.split();
  const textListUpper = textList.map((text) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  });
  const newText = textListUpper.join('');
  const resultBuffer = newText.replace(/([A-Z])/g, ' $1');
  const result = resultBuffer.slice(1);
  return result;
}
