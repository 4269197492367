import React from 'react';
import { Dropdown } from 'carbon-components-react';
import './DropdownOption.scss';
import { convertCamelCaseToUpperCase } from '../../utils/convert';
class DropdownOption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectdata: {},
    };
    this.selectOption = this.selectOption.bind(this);
  }

  selectOption = (e, title) => {
    this.setState((prevstate) => {
      let tempObj = { ...prevstate.selectdata };
      tempObj[title] = e['selectedItem'];
      Object.assign(prevstate.selectdata, tempObj);
      return this.props.onSelectOption(tempObj);
    });
  };

  render() {
    var data = this.props.options;
    var title = this.props.data;
    return (
      <div className="dropdown-box">
        <Dropdown
          key={this.props.index}
          ariaLabel="Dropdown"
          id="option-dropdowm"
          items={data}
          label="Choose an option"
          titleText={convertCamelCaseToUpperCase(title)}
          onChange={(e) => {
            this.selectOption(e, title);
          }}
          {...this.props}
        />
      </div>
    );
  }
}
export default DropdownOption;
